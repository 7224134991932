<template>
   <div class="card mb-0" name="foto" ref="foto">
      <div class="card-buttons">
         <div class="badge bg-danger" @click="remover">
            <i class="far fa-trash font-11 me-1"></i> Remover
         </div>
      </div>
      <img :src="foto" class="card-img-top" alt="icon" @error="imageError" />
   </div>
</template>

<script>

export default {
   name: "Foto",
   props: ["foto", "index"],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>

<style scoped>

.card[name="foto"] .card-buttons {
   opacity: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: #0006;
   transition: opacity 0.3s;
}

.card[name="foto"]:hover .card-buttons {
   opacity: 1;
}

.card[name="foto"] img {
	aspect-ratio: 1/1;
	object-fit: cover;
}

.card[name="foto"] .badge {
   font-weight: 400;
   font-size: 12px;
   padding: 7px 10px !important;
   cursor: pointer;
   border-radius: 3px;
}

</style>