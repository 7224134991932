<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Defina os produtos disponíveis no sistema de ecommerce e/ou caixa. Clique sobre um produto para visualizar seus respectivos códigos.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
			<pesquisaProduto class="dropdown-menu dropdown-menu-left" ref="pesquisaProduto" :showAdd="true" @setRetorno="setRetornoPesquisa($event)" @adicionar="abrirModal(null)" />
      </div>

      <div class="col-12" v-if="pesquisa.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2" v-if="etiquetas.codigos.length > 0">
							<a data-bs-toggle="offcanvas" href="#offcanvasEtiquetas" role="button" aria-controls="offcanvasEtiquetas" class="badge badge-default bg-theme pulse-effect mb-1">
								<i class="far fa-barcode font-10 me-1"></i> Lista impressão
							</a>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<produto v-for="(produto, index) in pesquisa.resultado" :key="index" :produto="produto" :index="index" :showSelect="false" :tempImpressao="etiquetas.codigos" 
							@editar="abrirModal($event)" @imprimirCodigo="addTempImpressao($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalProduto -->
      <div class="modal fade" id="modalProduto" tabindex="-1" aria-labelledby="modalProdutoLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalProdutoLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} produto {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-produto-tab" data-bs-toggle="pill" data-bs-target="#pills-produto" type="button" role="tab" aria-controls="pills-produto" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-lg-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation" v-if="dadosUsuario.usuarioPacotes.includes('Fiscal')">
										<button class="nav-link m-0" id="pills-imposto-tab" data-bs-toggle="pill" data-bs-target="#pills-imposto" type="button" role="tab" aria-controls="pills-imposto" aria-selected="false">
											<i class="far fa-percentage font-13"></i><span class="ms-1 d-none d-lg-inline"> Tributação</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-codigos-tab" data-bs-toggle="pill" data-bs-target="#pills-codigos" type="button" role="tab" aria-controls="pills-codigos" aria-selected="false">
											<i class="far fa-barcode font-12"></i><span class="ms-1 d-none d-lg-inline"> Códigos</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation" v-if="dadosUsuario.usuarioPacotes.includes('Ecommerce')">
										<button class="nav-link m-0" id="pills-imagens-tab" data-bs-toggle="pill" data-bs-target="#pills-imagens" type="button" role="tab" aria-controls="pills-imagens" aria-selected="false">
											<i class="far fa-image font-12"></i><span class="ms-1 d-none d-lg-inline"> Fotos</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-precos-tab" data-bs-toggle="pill" data-bs-target="#pills-precos" type="button" role="tab" aria-controls="pills-precos" aria-selected="false">
											<i class="far fa-dollar-sign font-12"></i><span class="ms-1 d-none d-lg-inline"> Preços</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-estoque-tab" data-bs-toggle="pill" data-bs-target="#pills-estoque" type="button" role="tab" aria-controls="pills-estoque" aria-selected="false">
											<i class="far fa-box font-12"></i><span class="ms-1 d-none d-lg-inline"> Estoques</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-produto" role="tabpanel" aria-labelledby="pills-produto-tab">
								<div class="card mb-2">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-12 mb-3">
												<label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome *</label>
												<input type="text" class="form-control" v-model="modal.nome" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-tags color-theme font-11 me-1"></i> Família</label>
												<input type="text" class="form-control" v-model="modal.familia" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-file color-theme font-11 me-1"></i> Categoria *</label>
												<v-select name="Desktop" :options="listaCategorias" v-model="modal.categoria" label="nome" :reduce="c => c.nome" placeholder="Selecione uma opção" 
													@input="buscarSubcategorias(modal.categoria)">
													<template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Categorias" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.categoria" @change="buscarSubcategorias(modal.categoria)">
													<option v-for="(option, index) in listaCategorias" :key="index" :value="option.nome">{{ option.nome }}</option>
												</select>
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-copy color-theme font-11 me-1"></i> Subcategoria *</label>
												<v-select name="Desktop" :options="listaSubcategorias" v-model="modal.subcategoria" placeholder="Selecione uma opção">
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span>
														<small class="d-block lh-sm mb-1 opacity-90 font-11" v-if="pesquisa.categoria">Utilize a tela "Categorias" para adicionar</small>
														<small class="d-block lh-sm mb-1 opacity-90 font-11" v-else>Selecione uma categoria</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.subcategoria">
													<option v-for="(option, index) in listaSubcategorias" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-bookmark color-theme font-11 me-1"></i> Marca *</label>
												<v-select name="Desktop" :options="listaMarcas" v-model="modal.marca" label="nome" :reduce="m => m.nome" placeholder="Selecione uma opção">
													<template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Marcas" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.marca">
													<option v-for="(option, index) in listaMarcas" :key="index" :value="option.nome">{{ option.nome }}</option>
												</select>
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-ruler color-theme font-11 me-1"></i> Unidade medida *</label>
												<v-select name="Desktop" :options="listaUnidadeMedida" v-model="modal.unidadeMedida" label="tipo" :reduce="c => c.tipo" placeholder="Selecione uma opção">
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Unidades de medida" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.unidadeMedida">
													<option v-for="(option, index) in listaUnidadeMedida" :key="index" :value="option.tipo">{{ option.tipo }}</option>
												</select>
											</div>

											<uploadImagem :resultado="modal.banner" label="Banner" widthCropper="col-md-6" @set="addImagem($event, 'Principal')" />
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-imposto" role="tabpanel" aria-labelledby="pills-imposto-tab" v-if="dadosUsuario.usuarioPacotes.includes('Fiscal')">
								<div class="card mb-2">
									<div class="card-body px-12 pt-12 pb-0">
										<div class="row">
											<div class="col-12 mb-3">
												<div class="alert alert-secondary alert-dismissible fade show mb-0 shadow-none pe-3 py-12 font-13" role="alert">
													<strong>Dados fiscais</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
													<span>Produtos sem dados fiscais cadastrados não aparecerão no caixa caso a respectiva loja tenha a opção "Gerar notas fiscais" habilitada.</span>
												</div>
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-file color-theme font-11 me-1"></i> Tributação</label>
												<v-select name="Desktop" :options="listaTributacoes" v-model="modal.idTributacao" label="nome" :reduce="t => t.id" placeholder="Selecione uma opção">
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Tributações" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.idTributacao">
													<option v-for="(option, index) in listaTributacoes" :key="index" :value="option.id">{{ option.nome }}</option>
												</select>
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-calculator color-theme font-10 me-1"></i> NCM</label>
												<div class="input-group">
													<div class="input-group-text">
														<i class="fal cursor-pointer" :class="modal.searchNCM.toggle ? 'fa-undo' : 'fa-search'" @click="modal.searchNCM.toggle = !modal.searchNCM.toggle"
															:title="modal.searchNCM.toggle ? 'Voltar' : 'Buscar'"></i>
													</div>
													<input v-if="!modal.searchNCM.toggle" @click="modal.searchNCM.toggle = true;" :value="modal.ncm" title="Buscar NCM" 
														type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção">
													<autoCompleteNCM v-else class="col" @submit="modal.ncm = $event; modal.searchNCM.toggle = false;" />
												</div>
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-calculator color-theme font-10 me-1"></i> CEST</label>
												<input type="text" class="form-control" v-model="modal.cest" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-qrcode color-theme font-11 me-1"></i> Cód. Benefício fiscal</label>
												<input type="text" class="form-control" v-model="modal.codBeneficioFiscal" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="tab-pane fade" id="pills-codigos" role="tabpanel" aria-labelledby="pills-codigos-tab">
								<div class="card mb-2">
									<div class="card-body">
										<h6 class="mb-0 font-14 weight-400">
											<span>{{ modal.codigos.length }} código(s) encontrado(s)</span>
											<a href="javascript:;" class="float-end font-16" data-bs-toggle="modal" data-bs-target="#modalCodigo"><i class="fas fa-plus color-theme"></i></a>
										</h6>
										<div class="w-100 my-5 text-center" v-if="modal.codigos.length == 0">
											<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
											<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
										</div>
									</div>
								</div>
								<div class="row">
									<codigo v-for="(codigo, index) in modal.codigos" :key="index" :codigo="codigo" :index="index" @remover="removerCodigo($event)" />
								</div>
							</div>

							<div class="tab-pane fade" id="pills-imagens" role="tabpanel" aria-labelledby="pills-imagens-tab" v-if="dadosUsuario.usuarioPacotes.includes('Ecommerce')">
								<div class="card mb-2">
									<div class="card-body">
										<h6 class="mb-0 font-14 weight-400">
											<span>{{ modal.fotos.length }} foto(s) encontrada(s)</span>
											<a href="javascript:;" class="float-end font-16" data-bs-toggle="modal" data-bs-target="#modalFoto"><i class="fas fa-plus color-theme"></i></a>
										</h6>
										<div class="w-100 my-5 text-center" v-if="modal.fotos.length == 0">
											<i class="fal fa-video-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
											<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 px-0">
										<div class="cards-150 cards-sm-200">
											<foto v-for="(foto, index) in modal.fotos" :key="index" :foto="foto" :index="index" @remover="removerImagem($event)" />
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-precos" role="tabpanel" aria-labelledby="pills-precos-tab">
								<div class="row">
									<preco v-for="(preco, index) in modal.precos" :key="index" :preco="preco" :index="index" :showEdit="true" :showCusto="false" :showEcommerce="true" />
									<div class="col-12 my-5 text-center" v-if="modal.precos.length == 0">
										<i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum preço encontrado</h4>
									</div>
								</div>
							</div>
							
							<div class="tab-pane fade" id="pills-estoque" role="tabpanel" aria-labelledby="pills-estoque-tab">
								<div class="row">
									<estoque v-for="(estoque, index) in modal.estoque" :key="index" :estoque="estoque" :index="index" />
									<div class="col-12 my-5 text-center" v-if="modal.estoque.length == 0">
										<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum estoque encontrado</h4>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarProduto">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalCodigo -->
      <div class="modal fade" id="modalCodigo" tabindex="-1" aria-labelledby="modalCodigoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalCodigoLabel">Adicionar código</h5>
                  <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#modalProduto" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<label><i class="far fa-info-circle color-theme font-11 me-1"></i> Observações</label>
										<textarea class="form-control" v-model="modal.codigo.observacao" maxlength="200"></textarea>
									</div>
									<div class="col-12">
										<label><i class="far fa-barcode color-theme font-11 me-1"></i> Código</label>
										<div class="input-group">
											<div class="input-group-text">
												<input class="form-check-input mt-0" type="checkbox" :checked="modal.codigo.ean == 'SEM GTIN'" @click="modal.codigo.ean != 'SEM GTIN' ? modal.codigo.ean = 'SEM GTIN' : modal.codigo.ean = ''">
												<span class="font-11 ms-2">SEM GTIN</span>
											</div>
											<input type="text" class="form-control" v-model="modal.codigo.ean" maxlength="200" :disabled="modal.codigo.ean == 'SEM GTIN'">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="addCodigo">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalProduto">Voltar</button>
               </div>
				</div>
			</div>
		</div>

		<!-- modalFoto -->
      <div class="modal fade" id="modalFoto" tabindex="-1" aria-labelledby="modalFotoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalFotoLabel">Adicionar foto</h5>
                  <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#modalProduto" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body p-12">
								<div class="row">
									<uploadImagem :resultado="null" label="Imagem" widthCropper="col-12" @set="addImagem($event, 'Complementar')" />
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalProduto">Voltar</button>
               </div>
				</div>
			</div>
		</div>

		<!-- offcanvasEtiquetas -->
		<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasEtiquetas" aria-labelledby="offcanvasEtiquetasLabel">
			<div class="offcanvas-header">
				<h5 id="offcanvasRightLabel">Lista de impressão</h5>
				<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body">
				<etiqueta v-for="(etiqueta, index) in etiquetas.codigos" :key="index" :etiqueta="etiqueta" :index="index" @remover="removerTempImpressao($event)" />
				
				<div class="col-12 my-5 text-center" v-if="etiquetas.codigos.length == 0">
					<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
					<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum código encontrado</h4>
				</div>
				<div class="col-12 my-5 text-center" v-else-if="dadosUsuario.usuarioLojas.length == 0">
					<i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
					<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Solicite permissão ou utilize a tela "Lojas" para adicionar</h4>
				</div>
			</div>
			<div class="offcanvas-footer">
				<button type="button" class="btn btn-primary" @click="imprimirCodigos" v-if="etiquetas.codigos.length > 0 && dadosUsuario.usuarioLojas.length > 0">Imprimir</button>
				<button type="button" class="btn btn-secondary" data-bs-dismiss="offcanvas">Fechar</button>
			</div>
		</div>

		<!-- impressaoCodigos -->
		<div class="col-12 m-0 p-0 d-none" id="impressaoCodigos">
			<div class="row row-cols-2 m-0">
				<div class="col m-0 p-0" v-for="(produto, index) in etiquetas.resultado" :key="index">
					<div class="row m-1 px-2 py-3 border border-dark border-dashed">
						<div class="col-12 px-2">
							<h5 class="text-truncate fs-5 text-capitalize mb-3">{{ String(produto.nome).toLowerCase() }}</h5>
						</div>
						<div class="col-6 px-2 mt-1">
							<vueBarcode :value="produto.codigo" background="#0000" fontSize="14" width="2" height="40" margin="0" textMargin="0">{{ produto.codigo }}</vueBarcode>
						</div>
						<div class="col-6 px-2 mt-1 text-end">
							<h5 class="text-truncate fs-3"><small>R$ </small>{{ parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import vueBarcode from 'vue-barcode'
import uploadImagem from '@/components/UploadImagem.vue'
import foto from '@/components/produtos/meusProdutos/Foto.vue'
import pesquisaProduto from '@/components/produtos/Pesquisa.vue'
import codigo from '@/components/produtos/meusProdutos/Codigo.vue'
import produto from '@/components/produtos/meusProdutos/Produto.vue'
import preco from '@/components/produtos/meusProdutos/LojaPreco.vue'
import autoCompleteNCM from '@/components/fiscal/AutoCompleteNCM.vue'
import etiqueta from '@/components/produtos/meusProdutos/Etiqueta.vue'
import estoque from '@/components/produtos/meusProdutos/EstoqueProduto.vue'

export default {
	name: 'Meus_produtos',
	data: function () {
		return {
			pesquisa: {'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'resultado': []},
			listaSubcategorias: [],
			modal: {
				'id': null, 'categoria': null, 'subcategoria': null, 'marca': null, 'familia': '', 'nome': '', 
				'codigos': [], 'cest': '', 'ncm': '', 'origem': '', 'banner': null, 'codBeneficioFiscal': null, 'idTributacao': null, 'fotos': [],
				'estoque': [], 'precos': [], 'codigo': {'id': null, 'ean': '', 'codigoProduto': '', 'observacao': ''},
				'searchNCM': {'toggle': false}
			},
			etiquetas: {'codigos': [], 'resultado': []}
		}
	},
	computed: {
      ... mapState({
         listaUnidadeMedida: state => state.listaUnidadeMedida,
         listaTributacoes: state => state.listaTributacoes,
         listaCategorias: state => state.listaCategorias,
         dadosUsuario: state => state.dadosUsuario,
         listaMarcas: state => state.listaMarcas,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		produto, codigo, foto, uploadImagem, preco, estoque, pesquisaProduto, vueBarcode, autoCompleteNCM, etiqueta
	},
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Produtos',
				'resultado': this.pesquisa.resultado, 
				'campos':  ['id', 'nome', 'marca', 'categoria', 'subcategoria', 'familia', 'cest', 'ncm', 'codBeneficioFiscal'], 
				'lista': {'nome': 'codigos', 'campos': ['id', 'ean', 'codigoProduto', 'observacao']}
			})
		},
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		abrirModal : function (produto) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)

			this.$axios({
				method: 'get',
				url: this.urlRest +'produtos/getProduto',
				params: {
					id: produto == null ? null : produto.id
				}
			}).then(response => {
				$('#modalProduto').modal('show')

				this.modal = {
					'id': response.data.id, 
					'categoria': response.data.categoria, 
					'subcategoria': response.data.subcategoria, 
					'marca': response.data.marca, 
					'familia': response.data.familia, 
					'nome': response.data.nome, 
					'codigos': response.data.codigos, 
					'cest': response.data.cest, 
					'ncm': response.data.ncm, 
					'origem': response.data.origem,
					'codBeneficioFiscal': response.data.codBeneficioFiscal,
					'idTributacao': response.data.idTributacao,
					'banner': response.data.banner,
					'fotos': response.data.fotos,
					'estoque': response.data.estoque, 
					'precos': response.data.precos, 
					'unidadeMedida': response.data.unidadeMedida, 
					'codigo': {'id': null, 'ean': '', 'codigoProduto': '', 'observacao': ''}, 
					'searchNCM': {'toggle': false}
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		setRetornoPesquisa : function (retorno) {
			this.pesquisa = this.$options.data.call(this).pesquisa;
			Object.assign(this.$data.etiquetas, this.$options.data.apply(this).etiquetas)
			setTimeout(() => this.pesquisa = retorno, 50);
		},
		buscarSubcategorias : function (categoria) {
			if (categoria == null) {
				this.modal.subcategoria = null
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
         this.$store.dispatch('getSubcategorias', categoria).then((data) => {
				this.listaSubcategorias = data

				if (data.length > 0) {
					this.modal.subcategoria = data[0]
				}
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		addTempImpressao : function (codigo) {
			let index = this.etiquetas.codigos.findIndex(c => c.id == codigo.id)
			
			if (index == -1) {
				this.etiquetas.codigos.push(codigo)
			} else {
				this.etiquetas.codigos.splice(index, 1)
			}
		},
		removerTempImpressao : function (index) {
			this.etiquetas.codigos.splice(index, 1)
		},
		addCodigo : function () {
			if (this.modal.codigo.ean == null || String(this.modal.codigo.ean).trim().length == 0) {
				this.modal.codigo.ean = 'SEM GTIN'
			}

			this.modal.codigos.push(JSON.parse(JSON.stringify(this.modal.codigo)))
			Object.assign(this.$data.modal.codigo, this.$options.data.apply(this).modal.codigo)
			$('#modalCodigo').modal('hide')
			$('#modalProduto').modal('show')
		},
		removerCodigo : function (index) {
			this.modal.codigos.splice(index, 1)
		},
		addImagem : function (img, local) {
			$('#modalFoto').modal('hide')
			$('#modalProduto').modal('show')

			switch (local) {
				case 'Principal':
					this.modal.banner = img;
				break;
				case 'Complementar':
					this.modal.fotos.push(img);
				break;
			}
      },
		removerImagem : function (index) {
			this.modal.fotos.splice(index, 1)
		},
		imprimirCodigos : function () {
			let lojas = {}
			$("#offcanvasEtiquetas").offcanvas("hide")

			for (var i = 0; i < this.dadosUsuario.usuarioLojas.length; i++) {
				lojas[JSON.stringify(this.dadosUsuario.usuarioLojas[i])] = (this.dadosUsuario.usuarioLojas[i].nomeFantasia +' - nº '+ this.dadosUsuario.usuarioLojas[i].numeroLoja)
			}

			Swal.fire({
				icon: 'warning',
				title: 'Imprimir códigos',
				text: 'Selecione a loja da qual os preços serão impressos.',
				showCancelButton: true,
				confirmButtonText: 'Confirmar',
				cancelButtonText: `Cancelar`,
				input: "select",
				inputOptions: lojas,
				inputPlaceholder: "Selecione uma opção",
				inputValidator: (value) => {
					if (!value) {
						return 'Opção inválida!'
					}
				}
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)

					this.$axios({
						method: 'post',
						url: this.urlRest +'produtos/imprimirCodigos',
						headers: {'Content-Type': 'application/json'},
						data: {'loja': JSON.parse(result.value).id, 'codigos': this.etiquetas.codigos.map(c => c.id)}

					}).then((response) => {
						this.etiquetas.resultado = response.data;
						this.etiquetas.codigos = [];

						setTimeout(() => this.$htmlToPaper('impressaoCodigos', {styles: ['./bootstrap.print.css']}), 250);

						this.$toast.fire({
							icon: 'success',
							title: 'Impressão gerada!'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')

								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		},
		salvarProduto : function () {
			this.$store.dispatch('base64toFile', Array.of(this.modal.banner)).then((banner) => this.$store.dispatch('base64toFile', this.modal.fotos).then((fotos) => {
				if (this.modal.nome == null || String(this.modal.nome).trim().length == 0 ||
					this.modal.categoria == null || String(this.modal.categoria).trim().length == 0 ||
					this.modal.subcategoria == null || String(this.modal.subcategoria).trim().length == 0 ||
					this.modal.unidadeMedida == null || String(this.modal.unidadeMedida).trim().length == 0 ||
					this.modal.marca == null || String(this.modal.marca).trim().length == 0) {
	
					this.$toast.fire({
						icon: 'error',
						title: 'Preencha todos os campos obrigatórios'
					});
	
					return
				}
	
				this.$store.dispatch('alternarTelaCarregamento', true)

				this.modal.precos.forEach(produto => {
					produto.preco = (produto.preco == null ? null : String(produto.preco).replace(/,/g, "."))
					produto.precoSite = (produto.precoSite == null ? null : String(produto.precoSite).replace(/,/g, "."))
					produto.custo = (produto.custo == null ? null : String(produto.custo).replace(/,/g, "."))
				});

				this.modal.estoque.forEach(produto => {
					produto.quantidade = (produto.quantidade == null ? null : String(produto.quantidade).replace(/,/g, "."))
				});
	
				let dataForm = new FormData();
				dataForm.append('info', JSON.stringify(this.modal));

				if (banner[0] instanceof File) {
					dataForm.append('banner', banner[0], String(banner[0].name).replace(/[^\w\s.]/gi, ''));
				}
				
				fotos.forEach(foto => {
					if (foto instanceof File) {
						dataForm.append('foto', foto, String(foto.name).replace(/[^\w\s.]/gi, ''));
					}
				});
	
				this.$axios({
					method: 'post',
					url: this.urlRest +'produtos/saveProduto',
					headers: {'Content-Type': 'multipart/form-data'},
					data: dataForm
	
				}).then(() => {
					$('#modalProduto').modal('hide')
					this.$refs.pesquisaProduto.buscarProdutos()
					
					this.$toast.fire({
						icon: 'success',
						title: 'Produto salvo!'
					});
				}).catch((error) => {
					if (error.response != undefined) {
						if (error.response.status == 408) {
							this.$store.dispatch('deslogar')
	
							this.$toast.fire({
								icon: 'warning',
								title: 'Sessão expirada!'
							});
						} else if (error.response.status == 400) {
							this.$toast.fire({
								icon: 'error',
								title: 'Código já existente!'
							});
						} else {
							this.$toast.fire({
								icon: 'error',
								title: 'Erro: ' + error.response.status
							});
						}
					}
				}).finally(() => {
					this.$store.dispatch('alternarTelaCarregamento', false)
				});
			}))
		}
	},
	mounted() {
      this.$store.dispatch('getUnidadesMedida')
		this.$store.dispatch('getTributacoes')
      this.$store.dispatch('getCategorias')
		this.$store.dispatch('getMarcas')
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>