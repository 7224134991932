<template>
   <div class="col-lg-6 ps-lg-0">
      <div class="card mb-1" :class="index % 4 == 0 || index % 4 == 3 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm text-center text-sm-start">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ estoque.nome }}</h1>
                  <p class="font-10 mb-0 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ estoque.idEstoque }}</span>
                  </p>
               </div>
               <div class="w-max-content text-center mx-auto ms-sm-0 my-2 my-sm-0">
                  <label><i class="far fa-box color-theme font-10 me-1"></i> Quantidade</label>
                  <input type="text" class="form-control text-center" v-model="estoque.quantidade" :disabled="!dadosUsuario.usuarioPermissoes.includes('Produtos | Editar estoque produto')" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'EstoqueProduto',
   props: ['estoque', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   watch: {
      'estoque.quantidade' : function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 3}).then((valor) => {
            this.estoque.quantidade = valor
         })
      }
   }
}

</script>

<style scoped>

label {
   font-size: 10.5px !important;
}

input {
   padding: 3px 9px !important;
   font-size: 12px !important;
   width: 115px;
}

</style>