<template>
   <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
      <div class="card-body p-12">
         <div class="row align-items-center">
            <div class="w-max-content pe-12">
               <img src="@/assets/images/iconeCodigo.png" alt="icon" @error="imageError" height="45" />
            </div>
            <div class="col">
               <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ etiqueta.ean == null ? 'SEM GTIN' : etiqueta.ean }}</h1>
            </div>
            <div class="w-max-content font-16 text-center">
               <div class="btn-icone text-red">
                  <span @click="remover"><i class="far fa-trash me-0"></i></span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Etiqueta',
   props: ['etiqueta', 'index'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>